import Link from "next/link";
import { Row, Col } from "react-bootstrap";
import { IoMdLogOut } from "react-icons/io";
import { BsPerson } from "react-icons/bs";
import { AiOutlineUser, AiOutlineShoppingCart } from "react-icons/ai";
import { GiTakeMyMoney } from "react-icons/gi";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/actions/userActions";
import MiniCart from "./MiniCart";

import getConfig from "next/config";
import ProductSearchBar from "../../ProductSearchBar";
const { publicRuntimeConfig } = getConfig();

const HeaderTop = () => {
  const userData = useSelector((_) => _.userData);
  const cartItems = useSelector((_) => _.cartData.cart);

  const { authenticated } = userData;
  const dispatch = useDispatch();

  const dispatchLogout = () => dispatch(logout());
  return (
    <div className='top-header d-none d-lg-block'>
      <Row className='align-items-center'>
        <Col md={4}>
          <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
            {/* <Form.Control as="select" name="languages" className="mr-2">
                <option value="en">English</option>
                <option value="fn">France</option>
              </Form.Control>

              <Form.Control as="select" name="countries" className="mr-3">
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBR">GBR</option>
              </Form.Control> */}
            <Link href='/'>
              <img
                style={{
                  display: "block",
                  maxHeight: "30px",
                  cursor: "pointer",
                }}
                className='logo-dark'
                src='/assets/images/logo_dark.webp'
                alt='logo'
              />
            </Link>
          </div>
        </Col>
        <Col md={4}>
          <ProductSearchBar />
        </Col>
        <Col md={4}>
          <div className='text-center text-md-right'>
            <ul className='header-list'>
              <li>
                <div
                  className='header-list__sell-button'
                  onClick={async () => {
                    window.location.href = "https://services.okwi.com/";

                    //April 4 2022 - Disabling ass cross-site-login is not setup

                    // if (!userData.authenticated) {
                    //   window.location.href = "https://services.okwi.com/";
                    // } else {
                    //   if (!userData.profileData.isOMSUser) {
                    //     window.location.href = "https://services.okwi.com/";
                    //     return;
                    //   }
                    //   const idToken = await firebase
                    //     .auth()
                    //     .currentUser.getIdToken();
                    //   let response = await fetch(
                    //     `${publicRuntimeConfig.NEXT_OMS_FRONTEND_BASE}users/cross-site-login`,
                    //     {
                    //       method: "GET",
                    //       headers: {
                    //         "Content-Type": "application/json",
                    //         Authorization: idToken,
                    //       },
                    //     }
                    //   );
                    //   response = await response.json();
                    //   window.location.href =
                    //     `${publicRuntimeConfig.NEXT_OMS_FRONTEND_BASE}` +
                    //     `login_v2.html?customToken=${response.token}`;
                    // }
                  }}
                >
                  <GiTakeMyMoney />
                  <span>Sell</span>
                </div>
              </li>

              <li
                className='position-relative'
                style={{ marginRight: cartItems.length > 0 && "20px" }}
              >
                <Link href='/other/cart'>
                  <a className='mini-cart-trigger'>
                    <AiOutlineShoppingCart />
                    <span>Cart</span>
                    {cartItems.length > 0 ? (
                      <span className='cart-count'>{cartItems.length}</span>
                    ) : (
                      ""
                    )}
                  </a>
                </Link>
                {/* mini cart */}
                <MiniCart cartItems={cartItems} />
              </li>
              {authenticated ? (
                <>
                  <li>
                    <Link href='/other/my-account'>
                      <a>
                        <BsPerson />
                        <span>Account</span>
                      </a>
                    </Link>
                  </li>
                  <li onClick={dispatchLogout}>
                    <a href='#'>
                      <IoMdLogOut />
                      Logout
                    </a>
                  </li>
                </>
              ) : (
                <li>
                  <Link href='/other/login'>
                    <a>
                      <AiOutlineUser />
                      <span>Login</span>
                    </a>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HeaderTop;
