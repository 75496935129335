import { useState, useEffect } from "react";
import Link from "next/link";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { IoIosMenu, IoMdLogOut } from "react-icons/io";
import { AiOutlineShoppingCart, AiOutlineLogin } from "react-icons/ai";
import { useMediaQuery } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsPerson } from "react-icons/bs";
import dynamic from "next/dynamic";
const HeaderTop = dynamic(() => import("./elements/HeaderTop"));
const SearchOverlay = dynamic(() => import("./elements/SearchOverlay"));
const MiniCart = dynamic(() => import("./elements/MiniCart"));
const MobileMenu = dynamic(() => import("./elements/MobileMenu"));
import { logout } from "../../redux/actions/userActions";

const HeaderOne = ({ cartItems }) => {
  const dispatch = useDispatch();
  const dispatchLogout = () => dispatch(logout());

  const userData = useSelector((_) => _.userData);
  const { authenticated } = userData;

  const [scroll, setScroll] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [offCanvasSearchActive, setOffCanvasSearchActive] = useState(false);
  const [offCanvasMobileMenuActive, setOffCanvasMobileMenuActive] =
    useState(false);
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");

  useEffect(() => {
    const header = document.querySelector(".header-wrap");
    setHeaderHeight(header.offsetHeight);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header
      className={`header-wrap header-with-topbar ${
        scroll > headerHeight ? "is-sticky" : ""
      }`}
    >
      {/* header top */}
      <HeaderTop />
      {!isLargerThan1000 && (
        <div className='bottom-header dark-skin'>
          <Container>
            <div className='bottom-header-container d-flex justify-content-between align-items-center position-relative'>
              {/* logo */}
              <Link href='/'>
                <a
                  className='navbar-brand'
                  style={{ opacity: isLargerThan1000 ? 0 : 100 }}
                >
                  <img
                    style={{ opacity: isLargerThan1000 ? 0 : 100 }}
                    className='logo-light'
                    src='/assets/images/logo_light.png'
                    alt='logo'
                  />
                  <img
                    style={{ opacity: isLargerThan1000 ? 0 : 100 }}
                    className='logo-dark'
                    src='/assets/images/logo_dark.webp'
                    alt='logo'
                  />
                </a>
              </Link>
              {/* icons */}
              {
                <ul className='header-icons d-flex'>
                  {!(scroll > headerHeight) && (
                    <li>
                      <a href='https://okwi.com'>
                        <a className='pl-2 pr-2 nav-link'>
                          <GiTakeMyMoney />
                        </a>
                      </a>
                    </li>
                  )}

                  <li className='d-none d-lg-block position-relative'>
                    <Link href='/other/cart'>
                      <a className='pr-3 nav-link mini-cart-trigger pr-lg-0'>
                        <AiOutlineShoppingCart />
                        {cartItems.length > 0 ? (
                          <span className='cart-count'>{cartItems.length}</span>
                        ) : (
                          ""
                        )}
                      </a>
                    </Link>
                    {/* mini cart */}
                    <MiniCart cartItems={cartItems} />
                  </li>

                  <li className='d-block d-lg-none position-relative'>
                    <Link href='/other/cart'>
                      <a className='pr-3 nav-link mini-cart-trigger pr-lg-0'>
                        <AiOutlineShoppingCart />
                        {cartItems.length > 0 ? (
                          <span className='cart-count cart-count--mobile'>
                            {cartItems.length}
                          </span>
                        ) : (
                          ""
                        )}
                      </a>
                    </Link>
                  </li>
                  {authenticated ? (
                    <li>
                      <Link href='/other/my-account'>
                        <a className='pl-2 pr-2 nav-link'>
                          <BsPerson />
                        </a>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {authenticated ? (
                    <li onClick={dispatchLogout}>
                      <a className='pl-2 pr-2 nav-link'>
                        <IoMdLogOut />
                      </a>
                    </li>
                  ) : (
                    <li>
                      {" "}
                      <Link href='/other/login'>
                        <a className='pl-2 pr-2 nav-link'>
                          <AiOutlineLogin />
                        </a>
                      </Link>
                    </li>
                  )}

                  <li className='d-block d-lg-none'>
                    <button
                      className='pr-0 nav-link mobile-menu-trigger'
                      onClick={() => {
                        setOffCanvasMobileMenuActive(true);
                      }}
                    >
                      <IoIosMenu />
                    </button>
                  </li>
                </ul>
              }
            </div>
          </Container>
        </div>
      )}
      {/* search overlay */}
      {/* April 5th 2022 - Disabling for now, throwing missing button error and this does not appear to be used  */}
      {/* <SearchOverlay
        activeStatus={offCanvasSearchActive}
        getActiveStatus={setOffCanvasSearchActive}
      /> */}

      {/* mobile menu */}
      <MobileMenu
        activeStatus={offCanvasMobileMenuActive}
        getActiveStatus={setOffCanvasMobileMenuActive}
      />
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData.cart,
  };
};

export default connect(mapStateToProps)(HeaderOne);
