import { useState } from "react";
import { IoIosMenu } from "react-icons/io";
import { SlideDown } from "react-slidedown";
import { NavLinks } from "./NavLinks";

const CategoryMenuFive = () => {
  const [categoryMenuExpandStatus, setCategoryMenuExpandStatus] =
    useState(false);

  return (
    <div className='header-categories-wrap'>
      <button
        className='category-menu-trigger'
        onClick={() => setCategoryMenuExpandStatus(!categoryMenuExpandStatus)}
      >
        <IoIosMenu /> ALL CATEGORIES
      </button>
      <nav className='category-menu dark-skin'>
        <SlideDown closed={categoryMenuExpandStatus ? false : true}>
          <NavLinks />
        </SlideDown>
      </nav>
    </div>
  );
};

export default CategoryMenuFive;
