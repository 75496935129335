import { useState, useEffect } from "react";
import Link from "next/link";
import { Row, Col } from "react-bootstrap";
import { animateScroll } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";
import {
  IoIosPhonePortrait,
  IoIosMailOpen,
  IoIosPin,
  IoIosArrowUp,
} from "react-icons/io";
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import viva_logo from "../../../public/assets/images/icons/viva_logo.svg";

const FooterFive = () => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const userData = useSelector((_) => _.userData);
  const { authenticated } = userData;
  const dispatch = useDispatch();
  const dispatchLogout = () => dispatch(logout());
  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <>
      <footer className='bg--grey'>
        <div className='footer-top footer-top--style-two'>
          <div className='custom-container'>
            <Row>
              <Col lg={4} sm={12}>
                <div className='widget'>
                  <div className='footer-logo'>
                    <Link href='/'>
                      <a>
                        <img src='/assets/images/logo_dark.webp' alt='logo' />
                      </a>
                    </Link>
                  </div>
                  <ul className='contact-info'>
                    <li>
                      <IoIosPin />
                      <p>
                        Armani Capital LTD, 18 Woodchester Court, Northwood, HA6
                        2HE
                      </p>
                    </li>
                    <li>
                      <IoIosMailOpen />
                      <a href='mailto:contact@okwi.com'>contact@okwi.com</a>
                    </li>
                    <li>
                      <IoIosPhonePortrait />
                      <p>020 805 03283</p>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={2} md={4} sm={6}>
                <div className='widget'>
                  <h6 className='widget-title'>My Account</h6>
                  <ul className='widget-links'>
                    <li>
                      <Link href='/other/my-account'>
                        <a>My Account</a>
                      </Link>
                    </li>
                    {authenticated && (
                      <li onClick={dispatchLogout}>
                        <Link href='#'>
                          <a>Logout</a>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>
              <Col lg={2} md={4} sm={6}>
                <div className='widget'>
                  <h6 className='widget-title'>Legal</h6>
                  <ul className='widget-links'>
                    <li>
                      <a
                        href='/other/terms'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href='/other/privacy'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Privacy & Cookie Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='footer-middle'>
          <div className='custom-container'>
            <Row>
              <Col>
                <div className='shopping-info'>
                  <Row className='justify-content-center'>
                    <Col md={4}>
                      <div className='icon-box icon-box--style3 overflow-hidden'>
                        <div className='icon-box__icon'>
                          <i className='flaticon-shipped' />
                        </div>
                        <div className='icon-box__content'>
                          <h5>Fast Dispatch</h5>
                          <p>
                            All of our products are dispatched within 1 working
                            day. All orders placed before 3PM are guaranteed to
                            go out the same day.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='icon-box icon-box--style3 overflow-hidden'>
                        <div className='icon-box__icon'>
                          <i className='flaticon-money-back' />
                        </div>
                        <div className='icon-box__content'>
                          <h5>30 Days Return</h5>
                          <p>
                            If you are not satisfied with your order, we offer a
                            30 Day money back guarantee for returns.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='icon-box icon-box--style3 overflow-hidden'>
                        <div className='icon-box__icon'>
                          <i className='flaticon-support' />
                        </div>
                        <div className='icon-box__content'>
                          <h5>24/7 Support</h5>
                          <p>
                            Our support team works around the clock to ensure
                            our customers needs are met.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='footer-bottom space-pt--30 space-pb--30'>
          <div className='custom-container'>
            <Row className='align-items-center'>
              <Col lg={6}>
                <p className='text-center mb-3 mb-lg-0'>
                  &copy; Copyright Okwi.com Limited{" "}
                  {new Date().getFullYear() + " "} is a subsidary of Armani
                  Capital Limited, Company No: 12783219, VAT No: 358339271
                </p>
              </Col>
              <Col lg={3} className='order-lg-first'>
                <ul className='social-icons text-center text-lg-left mb-3 mb-lg-0'>
                  <li>
                    <a
                      href='https://www.facebook.com/pages/category/Software-Company/Okwicom-113771800532347/'
                      className='sc_facebook'
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/okwiofficial/'
                      className='sc_instagram'
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.youtube.com/channel/UCdSK6XZXFMKw3Fcy6cFJNlw'
                      className='sc_youtube'
                    >
                      <FaYoutube />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://twitter.com/Okwi35434842'
                      className='sc_twitter'
                    >
                      <FaTwitter />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <button
          className={`scroll-top ${scroll > top ? "show" : ""}`}
          onClick={() => scrollToTop()}
        >
          <IoIosArrowUp />
        </button>
      </footer>
    </>
  );
};

export default FooterFive;
