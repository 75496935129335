import Link from "next/link";
import { connect, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { IoIosClose } from "react-icons/io";
import { removeFromCart } from "../../../redux/actions/cartActions";
import { calculateCurrentPrice } from "../../../lib/variation";
import { calculateCartTotal } from "../../../lib/cart";

const MiniCart = ({ cartItems }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const user = useSelector((state) => state.userData);
  const userData = user.profileData;

  const removeFromCartHandler = (item) => {
    dispatch(removeFromCart(item, addToast));
  };

  const { cartSubTotal, isCustomPrice } = calculateCartTotal({
    cartItems,
    pricingData: {
      productCustomPrices: userData.productCustomPrices,
      variantCustomPrices: userData.variantCustomPrices,
    },
  });

  return (
    <>
      {cartItems.length > 0 ? (
        <div className="cart-box">
          <ul className="cart-list">
            {cartItems.map((item, key) => {
              const { selectedVariant } = item;
              const slug = selectedVariant?.parentId;

              let { price } = calculateCurrentPrice(
                selectedVariant,
                item.quantity,
                null,
                cartItems
              );

              let isCustomPrice = false;
              if (
                selectedVariant &&
                userData?.variantCustomPrices?.hasOwnProperty(
                  selectedVariant?.id
                )
              ) {
                price = userData.variantCustomPrices[selectedVariant?.id].price;
                isCustomPrice = true;
              } else if (
                selectedVariant &&
                userData?.productCustomPrices?.hasOwnProperty(
                  selectedVariant.parentId
                )
              ) {
                price =
                  userData.productCustomPrices[selectedVariant.parentId].price;
                isCustomPrice = true;
              }

              return (
                <li key={key}>
                  <button
                    className="item-remove"
                    onClick={() => removeFromCartHandler(item)}
                  >
                    <IoIosClose />
                  </button>
                  <div className="single-item">
                    <div className="single-item__image">
                      <Link
                        href={`/shop/products/[slug]?slug=${slug}`}
                        as={"/shop/products/" + slug}
                      >
                        <a>
                          <img
                            src={
                              selectedVariant.images[0] ||
                              "/assets/images/logo_dark.webp"
                            }
                            alt="cart_thumb1"
                          />
                        </a>
                      </Link>
                    </div>

                    <div className="single-item__content">
                      <Link
                        href={`/shop/products/[slug]?slug=${item.slug}`}
                        as={"/shop/products/" + item.slug}
                      >
                        <a>{selectedVariant.name}</a>
                      </Link>
                      <span className="cart-quantity">
                        {`${item.quantity} x £${price?.toFixed(2)}`}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="cart-footer">
            <p className="cart-total">
              {isCustomPrice ? (
                <strong>Cart Total:</strong>
              ) : (
                <strong>Subtotal:</strong>
              )}
              <span className="cart-price">{`£${cartSubTotal.toFixed(
                2
              )}`}</span>
            </p>

            <div className="cart-buttons">
              <Link href="/other/cart">
                <a className="btn btn-fill-line view-cart">View Cart</a>
              </Link>
              <Link
                href={
                  user.authenticated
                    ? "/other/checkout"
                    : "/other/guest-checkout"
                }
              >
                <a className="btn btn-fill-out checkout">Checkout</a>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default MiniCart;
