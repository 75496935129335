import { useState, useEffect } from "react";
import Link from "next/link";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { IoIosMenu, IoMdLogOut } from "react-icons/io";
import { AiOutlineShoppingCart, AiOutlineLogin } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { useRouter } from "next/router";
import { useMediaQuery } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";
import { GiTakeMyMoney } from "react-icons/gi";
import dynamic from "next/dynamic";
import HeaderTop from "./elements/HeaderTop";
import MiniCart from "./elements/MiniCart";
import CategoryMenuFive from "./elements/CategoryMenuFive";
const MobileMenu = dynamic(() => import("./elements/MobileMenu"));

const HeaderFive = ({ cartItems }) => {
  const dispatch = useDispatch();
  const userData = useSelector((_) => _.userData);
  const { authenticated } = userData;

  const dispatchLogout = () => dispatch(logout());
  const [scroll, setScroll] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isHome, setIsHome] = useState(true);
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  const [
    offCanvasMobileCategoryMenuActive,
    setOffCanvasMobileCategoryMenuActive,
  ] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const header = document.querySelector(".header-wrap");
    setHeaderHeight(header.offsetHeight);
    window.addEventListener("scroll", handleScroll);

    const handleRouteChange = (url, other) => {
      if (url === "/") setIsHome(true);
      else setIsHome(false);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    if (router.pathname === "/") setIsHome(true);
    else setIsHome(false);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <>
      <header
        className={`header-wrap header-with-topbar ${
          scroll > headerHeight ? "is-sticky" : ""
        }`}
      >
        {/* header top */}
        <HeaderTop />

        {/* bottom header */}
        <div className='bottom-header dark-skin border-top border-bottom'>
          <div
            className={
              scroll > headerHeight ? "alt-container" : "custom-container"
            }
          >
            <Row className='align-items-center'>
              <Col
                lg={scroll > headerHeight ? 0 : 3}
                xs={isLargerThan1000 ? 0 : 2}
              >
                <div className='mobile-category d-block d-lg-none'>
                  <button
                    className='nav-link mobile-category-menu-trigger'
                    onClick={() => {
                      setOffCanvasMobileCategoryMenuActive(true);
                    }}
                  >
                    <IoIosMenu />
                  </button>
                </div>
                <div className='d-none d-lg-block'>
                  {/* category menu */}
                  <CategoryMenuFive />
                </div>
              </Col>
              <Col
                lg={scroll > headerHeight ? 12 : 9}
                xs={isLargerThan1000 ? 12 : 10}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Link href='/'>
                  <img
                    className='logo-sticky'
                    src='/assets/images/logo_dark.webp'
                    style={{ marginRight: "auto", cursor: "pointer" }}
                  />
                </Link>
                <div className='flex-row-reverse d-flex'>
                  {/* icons */}
                  {(scroll > headerHeight || !isLargerThan1000) && (
                    <ul className='header-icons d-flex'>
                      {!(scroll > headerHeight) && (
                        <li>
                          <a href='https://okwi.com'>
                            <span className='pl-2 pr-2 nav-link'>
                              <GiTakeMyMoney />
                            </span>
                          </a>
                        </li>
                      )}

                      <li className='d-none d-lg-block position-relative'>
                        <Link href='/other/cart'>
                          <a className='pl-2 pr-2 nav-link mini-cart-trigger'>
                            <AiOutlineShoppingCart />
                            {cartItems.length > 0 ? (
                              <span className='cart-count'>
                                {cartItems.length}
                              </span>
                            ) : (
                              ""
                            )}
                          </a>
                        </Link>
                        {/* mini cart */}
                        <MiniCart cartItems={cartItems} />
                      </li>
                      <li className='d-block d-lg-none position-relative'>
                        <Link href='/other/cart'>
                          <a className='pl-2 pr-2 nav-link mini-cart-trigger'>
                            <AiOutlineShoppingCart />
                            {cartItems.length > 0 ? (
                              <span className='cart-count cart-count--mobile'>
                                {cartItems.length}
                              </span>
                            ) : (
                              ""
                            )}
                          </a>
                        </Link>
                      </li>
                      {authenticated ? (
                        <li>
                          <Link href='/other/my-account'>
                            <a className='pl-2 pr-2 nav-link'>
                              <BsPerson />
                            </a>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {authenticated ? (
                        <li onClick={dispatchLogout}>
                          <a className='pl-2 pr-2 nav-link'>
                            <IoMdLogOut />
                          </a>
                        </li>
                      ) : (
                        <li>
                          {" "}
                          <Link href='/other/login'>
                            <a className='pl-2 pr-2 nav-link'>
                              <AiOutlineLogin />
                            </a>
                          </Link>
                        </li>
                      )}
                      {/* <li className="d-block d-lg-none">
                    <button
                      className="pr-0 nav-link mobile-menu-trigger"
                      onClick={() => {
                        setOffCanvasMobileMenuActive(true);
                      }}
                    >
                      <IoIosMenu />
                    </button>
                  </li> */}
                    </ul>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/* mobile navigation menu */}
        <MobileMenu
          activeStatus={offCanvasMobileCategoryMenuActive}
          getActiveStatus={setOffCanvasMobileCategoryMenuActive}
        />
      </header>
      {scroll > headerHeight && <div style={{ height: `${headerHeight}px` }} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData.cart,
  };
};

export default connect(mapStateToProps)(HeaderFive);
