import { HeaderFive } from "../components/Header";
import { FooterFive } from "../components/Footer";

const LayoutFive = ({ children, navPositionClass }) => (
    <>
      <HeaderFive navPositionClass={navPositionClass} />
      {children}
      <FooterFive />
    </>
  );

export default LayoutFive;
