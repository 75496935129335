import Link from "next/link";
import { AiOutlineHome } from "react-icons/ai";
import { BiFootball } from "react-icons/bi";
import PPEBlack from "../../../../public/icons/PPE Black.svg";
import VapeBlack from "../../../../public/icons/Vape Black.svg";
import JewelleryBlack from "../../../../public/icons/Jewellery Black.svg";

export function NavLinks() {
  return (
    <ul>
      <li>
        <Link href='/shop/products?category=E-Cigarette'>
          <a className='nav-link'>
            <VapeBlack
              style={{
                width: "50px",
                height: "50px",
                margin: "-11px -10px -9px -15px",
              }}
            />
            <span>E-Cigarette</span>
          </a>
        </Link>
      </li>
      <li>
        <Link href='/shop/products?category=Home and Garden'>
          <a className='nav-link'>
            <AiOutlineHome
              style={{ fontSize: "21px", margin: "4px 5px 4px 0px" }}
            />
            <span>Home and Garden</span>
          </a>
        </Link>
      </li>
      <li>
        <Link href='/shop/products?category=Jewellery'>
          <a className='nav-link'>
            <JewelleryBlack
              style={{
                width: "50px",
                height: "50px",
                margin: "-15px -10px -6px -14px",
              }}
            />
            <span>Jewellery</span>
          </a>
        </Link>
      </li>
      <li>
        <Link href='/shop/products?category=Toys and Hobbies'>
          <a className='nav-link'>
            <BiFootball
              style={{ fontSize: "21px", margin: "-2px 5px 5px 0" }}
            />
            <span>Toys and Hobbies</span>
          </a>
        </Link>
      </li>
    </ul>
  );
}
