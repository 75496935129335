import { HeaderOne } from "../components/Header";
import { FooterFive } from "../components/Footer";

const LayoutOne = ({ children, navPositionClass }) => (
    <>
      <HeaderOne navPositionClass={navPositionClass} />
      {children}
      <FooterFive />
    </>
  );

export default LayoutOne;
