import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { IoIosSearch } from "react-icons/io";
import Fuse from "fuse.js";
import Link from "next/link";
import { useRouter } from "next/router";

const ProductSearchBar = ({ products, mobile, getActiveStatus }) => {
  const router = useRouter();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const options = {
      includeScore: true,
      keys: ["name", "category"],
    };
    const fuse = new Fuse(products, options);
    const result = fuse.search(query);
    setResults(result);
  }, [query]);

  const ref = useRef();

  const handleFormSubmit = (e) => {
    if (query !== '') {
      if (e) {
        e.preventDefault();
      }
      router.push(`/shop/products?search=${query}`);
    }
  };

  return (
    <>
      <div
        ref={ref}
        onBlur={(e) => flag && setQuery("")}
        onMouseOver={(e) => setFlag(false)}
        onMouseOut={(e) => setFlag(true)}
        className={
          mobile
            ? "offcanvas-mobile-menu__search"
            : "product-search-form product-search-form--style-two d-none d-lg-block"
        }
      >
        {mobile ? (
          <form onSubmit={handleFormSubmit}>
            <input
              type="search"
              placeholder="Search here"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button type="submit">
              <IoIosSearch />
            </button>
          </form>
        ) : (
          <form onSubmit={handleFormSubmit}>
            <div className="input-group">
              <input
                className="form-control"
                placeholder="Search Product..."
                required
                type="text"
                value={query}
                onClick={(e) => {
                  e.preventDefault();
                }}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleFormSubmit();
                  }
                }}
              />
            </div>
            <button type="submit" className="search-btn">
              <IoIosSearch />
            </button>
          </form>
        )}
        {query && results.length > 0 && (
          <div
            style={{
              position: "absolute",
              zIndex: 99,
              padding: "10px",
              backgroundColor: "white",
              width: mobile ? "92%" : "100%",
              boxShadow: "0 5px 10px rgb(0 0 0 / 10%)",
              borderColor: "#E2E8F0",
            }}
          >
            <ul>
              {results.slice(0, 10).map(({ item }) => {
                if (item.approved) {
                  return (
                    <Link
                      href={`/shop/products/${item.slug}`}
                      key={item.slug}
                    >
                      <a
                        style={{
                          display: "flex",
                          width: "100%",
                          margin: "10px 5px",
                        }}
                      >
                        <img
                          src={item.image[0]}
                          height={30}
                          width={30}
                          style={{
                            marginRight: "10px",
                            height: "30px",
                            width: "30px",
                          }}
                        />
                        <li>{item.name}</li>
                      </a>
                    </Link>
                  );
                } else {
                  return <></>;
                }
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.productData,
  };
};

export default connect(mapStateToProps)(ProductSearchBar);
